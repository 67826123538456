import React from "react";

import { Link } from "gatsby";

import { PATHS, SOCIAL, SERVICES, CONTACT } from "../../config/urls";

import Life from "../../images/LIFE.svg";

import facebookRed from "./assets/facebookRed.svg";
import linkedInRed from "./assets/linkedInRed.svg";
import instagramRed from "./assets/instagramRed.svg";

import AOS from "aos";

import {
  FooterContainer,
  InternalWrapper,
  Logo,
  SectionDiv,
  Title,
  Text,
  ItemsDiv,
  ListItem,
  LogosDiv,
  CustomLink,
  CustomLinkHome,
  ContactDiv,
  Disclaimer,
  SocialMedia,
  SocialMediaIcon,
  FooterContent,
} from "./styles";

const Footer = () => {
  React.useEffect(
    () => {
      AOS.init({
        duration: 2000,
        once: true,
      });
    },
    [],
    AOS
  );

  return (
    <FooterContainer>
      <InternalWrapper>
        <FooterContent>
          <LogosDiv>
            <Link to={PATHS.INDEX}>
              <Logo src={Life} alt="Life logo" />
            </Link>
            <SocialMedia>
              <a href={SOCIAL.FACEBOOK} target="__blank">
                <SocialMediaIcon src={facebookRed} alt="facebook" />
              </a>
              <a href={SOCIAL.LINKEDIN} target="__blank">
                <SocialMediaIcon src={linkedInRed} alt="linkedin" />
              </a>
              <a href={SOCIAL.INSTAGRAM} target="__blank">
                <SocialMediaIcon src={instagramRed} alt="instagram" />
              </a>
            </SocialMedia>
          </LogosDiv>
          <ItemsDiv>
            <SectionDiv>
              <Link to={PATHS.MEET_OUR_TEAM}>
                  <Title>Our Team</Title>
              </Link>
            </SectionDiv>
            <SectionDiv>
              <Title>Our Services</Title>
              <ul>
                <ListItem>
                  <CustomLinkHome to={PATHS.INDEX}>Home Care</CustomLinkHome>
                </ListItem>
                <ListItem>
                  <CustomLink href={SERVICES.HOME_THERAPY}>
                    Home Therapy
                  </CustomLink>
                </ListItem>
                <ListItem>
                  <CustomLink href={SERVICES.RIDE}>Ride</CustomLink>
                </ListItem>
                <ListItem>
                  <CustomLink href={SERVICES.DME}>
                    Durable Medical Equipment
                  </CustomLink>
                </ListItem>
                <ListItem>
                  <CustomLink href={SERVICES.HOSPICE}>
                    Hospice
                  </CustomLink>
                </ListItem>
              </ul>
            </SectionDiv>
            <SectionDiv>
              <Title>Coverage Area</Title>
              <ul>
                <ListItem>Essex</ListItem>
                <ListItem>Morris</ListItem>
                <ListItem>Union</ListItem>
                <ListItem>Somerset</ListItem>
                <ListItem>Passaic</ListItem>
                <ListItem>Bergen</ListItem>
                <ListItem>Middlesex</ListItem>
              </ul>
            </SectionDiv>
            <SectionDiv>
              <Title>Contact</Title>
              <ContactDiv>
                <a
                  href="https://g.page/Life-home-care-senior-new-jersey?share"
                  target="_blank"
                  rel="noopener nofollow noreferrer"
                >
                  <Text>
                    70 South Orange Ave, Suite 220, Livingston, NJ 07039
                  </Text>
                </a>
              </ContactDiv>
              <ContactDiv>
                <a
                  href="https://www.google.com.ar/maps/place/139+Harristown+Rd+%23101b,+Glen+Rock,+NJ+07452,+EE.+UU./@40.9505828,-74.1434916,16.75z/data=!4m5!3m4!1s0x89c2fc96afaa2b51:0x32c94a0060073fb6!8m2!3d40.9506459!4d-74.141208"
                  target="_blank"
                  rel="noopener nofollow noreferrer"
                >
                  <Text>139 Harristown Rd, Suite 101B, <br />Glen Rock, NJ 07452</Text>
                </a>
              </ContactDiv>
              <ContactDiv>
                <a
                  href="https://maps.app.goo.gl/3KrvTNMPtEmDGe257"
                  target="_blank"
                  rel="noopener nofollow noreferrer"
                >
                  <Text>80 Cottontail Lane, Suite 430, <br />Somerset, NJ 08873</Text>
                </a>
              </ContactDiv>
              <ContactDiv mobile>
                <a href={`tel:${CONTACT.PHONE}`}>
                  <Text>{CONTACT.PHONE_TO_DISPLAY}</Text>
                </a>
              </ContactDiv>
              <ContactDiv>
                <a href={`mailto:${CONTACT.MAIL}`}>
                  <Text>{CONTACT.MAIL}</Text>
                </a>
              </ContactDiv>
            </SectionDiv>
          </ItemsDiv>
        </FooterContent>
        <FooterContent>
          <Disclaimer>
          Life Ride, Life Home Care, Life DME, Life Home Therapy, Life Hospice, Life Health Group logos are all Registered in U.S. Patent and Trademark Oﬃce
          </Disclaimer>
        </FooterContent>
      </InternalWrapper>
    </FooterContainer>
  );
};

export default Footer;
