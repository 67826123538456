import { BLOG_URI } from "./index"

export const PATHS = {
  INDEX: "/",
  CAREERS: "/careers",
  HOMECARE_BLOG: "/blog",
  CREDENTIALS: "/company-credentials",
  MEET_OUR_TEAM: "/meet-our-team",
  SERVICES: "https://homecare.life/services",
  ABOUT_US: "https://homecare.life/about-us",
  CONTACT: "https://homecare.life/contact-us",
  BLOG: `${BLOG_URI}/sample`,
  PDF_TREATMENT: "/pdf/TreatmentReferralForm.pdf",
  MAP_LINK:
    "https://www.google.com/maps/d/embed?mid=1JGrhTpDy18vV_bwuhU2GHJn7_MtsBeA&ehbc=2E312F",
  OUR_COMPANY_CREDENTIALS: "https://homecare.life/our-company-credentials",
  BLOG_MENU: "https://homecare.life/blog",
  PARTNERS_MENU: "https://homecare.life/partners",
  GET_STARTED: "https://homecare.life/get-started",
  LOCATIONS: '/locations',
  LOCATIONS_SERVICE_MAP: "https://maps.app.goo.gl/zxAtsttez8sPHAQS9",
  LOCATIONS_SERVICE_MAP_TWO: "https://maps.app.goo.gl/RGZBL4cTwKVFBNZ48",
  LOCATIONS_SERVICE_MAP_THREE: "https://maps.app.goo.gl/3KrvTNMPtEmDGe257",
  FAQ: "/faq"
}

export const CONTACT = {
  PHONE_TO_DISPLAY: "973-273-3441",
  PHONE: "9732733441",
  MAIL: "hello@homecare.life",
  MAIL_CONTACT_US_LOCATIONS: 'michelle@homecare.life',
}

export const SOCIAL = {
  FACEBOOK: "https://www.facebook.com/lifehomecarenewjersey",
  INSTAGRAM: "https://www.instagram.com/lifehomecare.nj/",
  LINKEDIN: "https://www.linkedin.com/company/lifehomecarenewjersey/",
  HOME_CARE: "https://homecare.life/",
  RIDE: "https://ride.life/",
  DME: "https://www.mydme.life/",
}

export const SERVICES = {
  HOME_THERAPY: "https://www.hometherapy.life/",
  HOME_CARE: "https://homecare.life/",
  RIDE: "https://ride.life/",
  DME: "https://www.mydme.life/",
  HOSPICE: "https://hospicecare.life/"
}
