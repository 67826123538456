import {
  Arrow,
  ContactUs,
  Container,
  DropdownContent,
  HamburguerIcon,
  Icon,
  MenuContainer,
  MenuDesktop,
  MenuDesktopBrand,
  MenuDesktopItem,
  MenuDesktopList,
  MenuDesktopServicesItem,
  MenuMobile,
  MenuMobileButton,
  MobileServices,
  PhoneContainer,
  PhoneLink,
  PhoneNumber,
  WhiteLine,
} from "./styles";
import { CONTACT, PATHS, SERVICES } from "../../config/urls";

import ArrowDown from "../../images/arrow-down.svg";
import { Link } from "gatsby";
import React from "react";
import hamburguerIcon from "./assets/hamburguer-menu-icon.svg";
import logoHomeCare from "../../images/logo-home-care-pink.svg";
import phone from "../../images/home-care/Tel.svg";

const Topbar = () => {
  const [open, setOpen] = React.useState(false);
  const [openMobileServices, setOpenMobileServices] = React.useState(false);
  const [openMobileOurCompany, setOpenMobileOurCompany] = React.useState(false);

  return (
    <>
      <MenuContainer>
        <MenuDesktop>
          <Container>
            <MenuDesktopBrand>
              <Link to={PATHS.INDEX}>
                <img src={logoHomeCare} alt="Logo Home Care" />
              </Link>
            </MenuDesktopBrand>
            <MenuDesktopList>
              <Link to={PATHS.INDEX}>
                <MenuDesktopItem>Home</MenuDesktopItem>
              </Link>
              <MenuDesktopServicesItem>
                Other services <img src={ArrowDown} alt="arrow down" />
                <div>
                  <a href={SERVICES.HOME_THERAPY}>home therapy</a>
                  <a href={SERVICES.DME} target="_blank" rel="noreferrer">
                    durable medical equipment
                  </a>
                  <a href={SERVICES.RIDE}>ride</a>
                  <a href={SERVICES.HOSPICE}>hospice</a>
                </div>
              </MenuDesktopServicesItem>
              <Link to={PATHS.PARTNERS_MENU}>
                <MenuDesktopItem>Trusted Partners</MenuDesktopItem>
              </Link>
              <Link to={PATHS.BLOG_MENU}>
                <MenuDesktopItem>Blog</MenuDesktopItem>
              </Link>
              <MenuDesktopServicesItem>
                Our Company <img src={ArrowDown} alt="arrow down" />
                <div>
                  <a href={PATHS.CAREERS}>Careers</a>
                  <a href={PATHS.CREDENTIALS}>Credentials</a>
                  <a href={PATHS.MEET_OUR_TEAM}>Our Team</a>
                  <a href={PATHS.LOCATIONS}>Locations</a>
                  <a href={PATHS.FAQ}>FAQ</a>
                </div>
              </MenuDesktopServicesItem>
              <PhoneContainer>
                <Icon src={phone} />
                <PhoneNumber>973-273-3441</PhoneNumber>
              </PhoneContainer>
              <MenuDesktopItem>
                <Link to={`${PATHS.INDEX}#callBack`}>
                  <ContactUs>Get Started</ContactUs>
                </Link>
              </MenuDesktopItem>
            </MenuDesktopList>
          </Container>
        </MenuDesktop>

        {/* MOBILE */}

        <MenuMobile>
          <Container>
            <MenuMobileButton>
              <HamburguerIcon
                onClick={() => setOpen(!open)}
                src={hamburguerIcon}
                alt="hamburguer menu"
              />
              <DropdownContent isOpen={open}>
                <ul>
                  <Link to={PATHS.INDEX}>Home</Link>
                  <li
                    onClick={() => setOpenMobileServices(!openMobileServices)}
                  >
                    other services
                    <Arrow
                      isServicesOpen={openMobileServices}
                      src={ArrowDown}
                    />
                  </li>
                  <MobileServices isServicesOpen={openMobileServices}>
                    <WhiteLine />
                    <a href={SERVICES.HOME_THERAPY}>home therapy</a>
                    <a href={SERVICES.DME} target="_blank" rel="noreferrer">
                      durable medical equipment
                    </a>
                    <a href={SERVICES.RIDE}>ride</a>
                    <a href={SERVICES.HOSPICE}>hospice</a>
                    <WhiteLine />
                  </MobileServices>
                  <Link to={PATHS.PARTNERS_MENU}>Trusted Partners</Link>
                  <Link to={PATHS.BLOG_MENU}>Blog</Link>
                  <li
                    onClick={() =>
                      setOpenMobileOurCompany(!openMobileOurCompany)
                    }
                  >
                    our company
                    <Arrow
                      isServicesOpen={openMobileOurCompany}
                      src={ArrowDown}
                    />
                  </li>
                  <MobileServices isServicesOpen={openMobileOurCompany}>
                    <WhiteLine />
                    <a href={PATHS.CAREERS}>Careers</a>
                    <a href={PATHS.CREDENTIALS}>Credentials</a>
                    <a href={PATHS.MEET_OUR_TEAM}>Our Team</a>
                    <a href={PATHS.LOCATIONS}>Locations</a>
                    <a href={PATHS.FAQ}>Faq</a>
                  </MobileServices>
                </ul>
              </DropdownContent>
            </MenuMobileButton>
            <MenuDesktopBrand>
              <Link to={PATHS.INDEX}>
                <img src={logoHomeCare} alt="Logo Home Care" />
              </Link>
            </MenuDesktopBrand>
          </Container>
        </MenuMobile>
      </MenuContainer>
    </>
  );
};

export default Topbar;
