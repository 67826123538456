export const COLORS = {
  black: "#000000",
  gray: "#464748",
  grayTopbarItem: "#707070",
  grayHover: "#3a3a3a",
  grayText: "#404040",
  darkGray: "#333333",
  graySection: "#EBEBEB",
  redTitle: "#e0281a",
  magenta: "#E01A73",
  vermilion: "#ec4747",
  white: "#FFFFFF",
  redTitle:"#EC4746",
  pinkDots:"#E01A73"
};
